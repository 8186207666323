import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

import App from '../../../layout/App';
import FeaturedResource from '../components/FeaturedResource';
import ResourcesList from '../components/ResourcesList';

import './index.css';

export class ProductUpdatesPage extends Component {
  static propTypes = {
    productUpdates: PropTypes.array.isRequired,
  };

  render() {
    const { productUpdates } = this.props;

    const firstProductUpdate = productUpdates[0];

    return (
      <App>
        <div className="component product-updates-page">
          <Helmet title="Product updates – ThinkReservations">
            <meta
              name="description"
              content="ThinkReservations is dedicated to continuously enhancing the tools that help your business succeed. Click for our latest updates!"
            />
          </Helmet>

          <FeaturedResource
            path={firstProductUpdate.path}
            title={firstProductUpdate.title}
            date={firstProductUpdate.date}
            author={firstProductUpdate.author}
            authorTitle={firstProductUpdate.authorTitle}
            authorCompany={firstProductUpdate.authorCompany}
            shortDescription={firstProductUpdate.shortDescription}
            videoLength={firstProductUpdate.videoLength}
            thumbnailImage={firstProductUpdate.thumbnailImage}
          />

          <ResourcesList resources={productUpdates} title="Recent updates" />
        </div>
      </App>
    );
  }
}

export default ProductUpdatesPage;
