import React from 'react';

import ProductUpdatesPage from '../../components/pages/resources/ProductUpdatesPage';

export default (props) => {
  const { pageContext } = props;

  const { resources } = pageContext;

  return <ProductUpdatesPage productUpdates={resources} />;
};
